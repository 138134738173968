import styled from 'styled-components';

const CheesePricesStyles = styled.div`
  align-items: center;
  background-color: ${props => props.theme.orange};
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: right;

  .CheesePricesText__container {
    margin: 10px;
    padding-right: 97px;

    p {
      max-width: 508px;
    }

    @media (max-width: 900px) { 
      padding-right: 10px;
    }
  }

  .Image__container {
    margin-top: -43px;
    padding-right: 95px;
  }

  p {
    color: ${props => props.theme.white};
    font-size: 35px;
    font-weight: 800;
    line-height: 48px;
    padding-bottom: 30px;
  }

  .CheesePrices__button {
    background: ${props => props.theme.white};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.white};
    box-sizing: border-box;
    color: ${props => props.theme.orange};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;

    &:hover, &:active {
      background: transparent;
      color: ${props => props.theme.white};
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    text-align: center;

    p {
      font-size: 30px;
      font-weight: 800;
      line-height: 41px;
      text-align: center;
    }

    .Image__container {
      display: none;
    }
  }

`;

export default CheesePricesStyles;
