import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// styled components
import CheesePricesStyles from './styles/cheese-prices-styles';

const CheesePrices = () => {
  const data = useStaticQuery(graphql`
    query {
      quattroPull: file(relativePath: { eq: "quattro-pull.png" }) {
        childImageSharp {
          fixed(width: 409) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <CheesePricesStyles>
      <div className="CheesePricesText__container">
        <p>Check today&apos;s cheese prices with Cheese Reporter</p>
        {/* TODO: Request cheese price data, and display results within the page */}
        <a
          className="CheesePrices__button"
          href="https://www.cmegroup.com/trading/agricultural/spot-call-data.html"
          style={{ textDecoration: 'none' }}
        >
          Check Prices
        </a>
      </div>
      <div className="Image__container">
        <Img fixed={data.quattroPull.childImageSharp.fixed} />
      </div>
    </CheesePricesStyles>
  );
};

export default CheesePrices;
