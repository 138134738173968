import styled from 'styled-components';

const ChalkboardStyles = styled.div`
  align-items: center;
  background: ${props => props.theme.gray};
  display: flex;
  flex-direction: column;
  font-size: 28px; 
  font-weight: 800;
  justify-content: center;
  line-height: 34px;
  padding: 30px;
  text-align: center;
  width: 100%;

  p {
    padding: 20px 0 20px 0;
  }

  .JoinNow__button {
    background: ${props => props.theme.orange};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.orange};
    box-sizing: border-box;
    color: ${props => props.theme.white};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;

    &:hover, &:active {
      background: transparent;
      color: ${props => props.theme.orange};
    }
  }

  @media (max-width: 768px) {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
  }
`;

export default ChalkboardStyles;
