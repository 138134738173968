import styled from 'styled-components';
import HomeHero from '../../images/iolcf-home-hero.jpg';

const JoinNowStyles = styled.div`
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background: linear-gradient(
      rgba(255, 103, 29, 0.95),
      rgba(255, 103, 29, 0.95)
    ),
    url(${HomeHero});
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 170px 30px 0px 30px;
  text-align: center;
  width: 100%;

  h1 {
    color: ${props => props.theme.white};
    font-size: 40px;
    font-weight: 800;
    line-height: 54px;
  }

  p {
    color: ${props => props.theme.white};
    font-size: 25px;
    font-weight: 800;
    line-height: 34px;
    padding-bottom: 20px;
    text-align: center;
  }

  .JoinNow__button {
    background: ${props => props.theme.white};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.white};
    box-sizing: border-box;
    color: ${props => props.theme.orange};
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    padding: 13px 44px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    z-index: 100;

    &:hover,
    &:active {
      background: transparent;
      color: ${props => props.theme.white};
    }
  }

  .ClipboardCaesar__container {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    margin-left: 500px;
    margin-top: -46px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .DottedLine {
    margin-top: 53px;
    padding-right: 4px;

    @media (max-width: 768px) {
      visibility: hidden;
    }
  }

  @media (max-width: 768px) {
    padding: 148px 38px;

    h1 {
      font-size: 30px;
      font-weight: 800;
      line-height: 41px;
    }

    p {
      font-size: 18px;
      font-weight: 800;
      line-height: 25px;
    }
  }
`;

export default JoinNowStyles;
