import styled from 'styled-components';

const HomePageMemberProgramsStyles = styled.div`
    background-color: ${props => props.theme.gray};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;

    .center {
        margin: 0 auto;
        max-width: 1200px;
    }

    .MemberProgramTitle__container {
        align-items: center;
        color: ${props => props.theme.orange};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 78px;
        padding: 15px;

        p {
            color: ${props => props.theme.orange};
            font-size: 18px;
            font-weight: 800;
            line-height: 25px;
        }
    }

    .MemberProgramsTitle--viewAll {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;

        &:hover {
            color: ${props => props.theme.lightOrange};

            .icon-arrow,
            .icon-arrow:before,
            .icon-arrow:after {
                background-color: ${props => props.theme.lightOrange};
            }
        }
    }

    .MemberPrograms__container {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        justify-content: center;
    }

    .MemberProgram__container {
        align-items: flex-end;
        background-color: ${props => props.theme.white};
        display: flex;
        flex-direction: column;
        height: 270px;
        justify-content: flex-end;
        margin: 20px;
        text-decoration: none;
        width: 300px;

        img {
            width: 80%;
            display: flex;
            align-self: center;
        }

        /* &:hover {

    } */
    }

    .MemberPrograms__image {
        margin-bottom: 35px;
    }

    .memberprograms__image_pepsi {
        min-height: 0px;
        object-fit: contain;
        margin-top: 16px;
        margin-bottom: 0px;
    }

    .MemberProgram__name {
        background-color: ${props => props.theme.darkGray};
        color: ${props => props.theme.white};
        font-size: 18px;
        font-weight: 800;
        line-height: 25px;
        padding: 22px 0 22px 0;
        text-align: center;
        width: 100%;

        &.margin-top-m {
            margin-top: 50px;
        }

        &.margin-top-s {
            margin-top: 25px;
        }
    }

    @media (max-width: 768px) {
        padding: 0;
    }

    .icon-arrow {
        width: 30px;
        height: 3px;
        background-color: ${props => props.theme.orange};
        position: relative;
        border-radius: 4px;
        margin-left: 10px;
    }

    .icon-arrow:before {
        content: '';
        display: inline-block;
        width: 17px;
        height: 3px;
        background-color: ${props => props.theme.orange};
        transform: rotate(45deg);
        position: absolute;
        right: -3px;
        bottom: 5px;
    }

    .icon-arrow:after {
        content: '';
        display: inline-block;
        width: 17px;
        height: 3px;
        background-color: ${props => props.theme.orange};
        transform: rotate(-45deg);
        position: absolute;
        right: -3px;
        bottom: -5px;
    }
`;

export default HomePageMemberProgramsStyles;
