import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// styled components
import MissionStyles from './styles/mission-styles';

const MissionStatement = () => {
  const data = useStaticQuery(graphql`
    query {
      storeFront: file(relativePath: { eq: "store-front.png" }) {
        childImageSharp {
          fluid(maxWidth: 480) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <MissionStyles>
      <div className="Image__container">
        <Img fluid={data.storeFront.childImageSharp.fluid} />
      </div>
      <div className="Mission__container">
        <h1>Our Mission...</h1>
        <h1>...Building the Brand Together</h1>
        <p>
          To be an independent Little Caesars Franchisee Association
          that acts as the official voice of the membership –
          an association that is completely autonomous, accountable
          and responsive to issues relevant to its members with
          regards to conducting business as a Little Caesars’ franchisee.
          To have an organization that works to promote and
          enhance the investments made by its’ members in their franchise
          through cooperative communications, coordinated efforts
          and a focus on our common issues.
        </p>
      </div>
    </MissionStyles>
  );
};

export default MissionStatement;
