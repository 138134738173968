import React from 'react';
// import { Link } from 'gatsby';

// styled components
import HomePageMemberProgramsStyles from './styles/home-page-member-programs-styles';
import Title from './styles/title';

// TODO: query with gatsby-image
import elevanta from '../images/elevanta.png';
import usi from '../images/usi.jpg';
import XLT from '../images/xlt.png';
import EmcentrixLogo from '../images/EmCentrixLogo.jpg';
import LeasecakeLogo from '../images/LeasecakeLogo.png';
import UpShowLogo from '../images/upshow-logo.png';
import PepsiLogo from '../images/Pepsi-logo.jpg';
import prepWizardLogo from '../images/prep-wizard-logo.png';
import reachifyLogo from '../images/reachify-logo.png';

const HomePageMemberPrograms = () => (
    <HomePageMemberProgramsStyles>
        <div className="center">
            <div className="MemberProgramTitle__container">
                <Title>Vendor Programs</Title>
                <a
                    className="MemberProgramsTitle--viewAll"
                    style={{ textDecoration: 'none' }}
                    href="/vendor-directory"
                >
                    <p>view all partners</p>
                    <div className="icon-arrow" />
                </a>
            </div>
            <div className="MemberPrograms__container">
                <a
                    className="MemberProgram__container"
                    href="https://prep-wizard.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={prepWizardLogo} alt="prepwizard Logo" />
                    <p className="MemberProgram__name margin-top-m">
                        Business Services
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="https://get.reachify.io/iolcf/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={reachifyLogo} alt="reachify Logo" />
                    <p className="MemberProgram__name margin-top-m">
                        Business Services
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="http://www.pepsico.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="memberprograms__image_pepsi"
                        src={PepsiLogo}
                        alt="Pepsi logo"
                    />
                    <p className="MemberProgram__name margin-top-s">
                        Equipment
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="http://www.upshow.tv/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="MemberPrograms__image"
                        src={UpShowLogo}
                        alt="UpShow logo"
                    />
                    <p className="MemberProgram__name margin-top-s">
                        Business Services
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="https://try.leasecake.com/tenants/?utm_source=associations&utm_medium=website&utm_campaign=IOLCF"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img
                        className="MemberPrograms__image"
                        src={LeasecakeLogo}
                        alt="Leasecake logo"
                    />
                    <p className="MemberProgram__name margin-top-s">
                        Business Services
                    </p>
                </a>
                {/* <a
          className="MemberProgram__container"
          href="https://www.foodtodonate.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img className="MemberPrograms__image" src={FDC} alt="fdc logo" />
          <p className="MemberProgram__name margin-top-s">Business Services</p>
        </a> */}
                <a
                    className="MemberProgram__container"
                    href="http://www.elevantahealth.com/iolcf"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={elevanta} alt="elevanta logo" />
                    <p className="MemberProgram__name margin-top-s">
                        Health Insurance
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="https://www.usi.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={usi} alt="usi logo" />
                    <p className="MemberProgram__name margin-top-s">
                        Business Insurance
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="http://www.xltovens.com/IOLCF"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={XLT} alt="XLT logo" />
                    <p className="MemberProgram__name margin-top-m">
                        Equipment
                    </p>
                </a>
                <a
                    className="MemberProgram__container"
                    href="https://www.emcentrix.com/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <img src={EmcentrixLogo} alt="Emcentrix Logo" />
                    <p className="MemberProgram__name margin-top-m">
                        Business Services
                    </p>
                </a>
            </div>
        </div>
    </HomePageMemberProgramsStyles>
);

export default HomePageMemberPrograms;
