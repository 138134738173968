import styled from 'styled-components';

const MissionStyles = styled.div`
  background: ${props => props.theme.white};
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 100px 140px;

  .Image__container {
    /* hacking a fixed width for desktop */
    min-width: 480px;
  }

  .Mission__container {
    padding-left: 78px;
  }

  h1 {
    color: ${props => props.theme.orange};
    font-size: 35px;
    font-weight: 800;
    line-height: 48px;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 200%;
    padding-top: 20px;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
    padding: 0;

    .Image__container {
      /* fluid 100% width for tablet and mobile */
      min-width: none;
      width: 100%; 
    }

    .Mission__container {
      padding: 50px 30px;
    }

    h1 {
      font-weight: 800;
      font-size: 30px;
      line-height: 41px;
      text-align: center;
    }

    p {
      text-align: left;
    }
  }
`;

export default MissionStyles;
